import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-somatic-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_paywall_somatic_female_comp'
					),
			},
		},
	},
] as ExperimentConfig[]
