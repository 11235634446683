<template>
	<div class="funnel-container">
		<slot></slot>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sentry from '@/modules/Sentry'
import Jet from '@/modules/Jet'
import { defineFunnelByParams } from '@/funnels/funnels.ts'

export default {
	name: 'FunnelContainer',

	watch: {
		activeFunnelType: {
			async handler(newValue, oldValue) {
				if (newValue.funnel.id === oldValue?.funnel?.id) {
					// Может быть здесь правильнее проверять по appArea?
					return
				}
				if (this.$route.meta.isLanding && this.$route.query.utm_source) {
					await this.$store.dispatch('location/storeInitialPath', { force: true })
				}
				this.storeFunnel(newValue)
			},

			immediate: true,
		},
	},

	computed: {
		...mapGetters({
			getQueryParam: 'location/getQueryParam',
			property: 'survey/getPropertyValue',
			language: 'i18n/getLanguage',
			countryCode: 'getCountryCode',
			initialPath: 'location/getInitialPath',
			hostname: 'location/hostname',
		}),

		activeFunnelType() {
			return defineFunnelByParams(
				{
					appArea: this.getQueryParam('area') ?? null,
					initialPath: this.initialPath,
					country: this.countryCode,
					domain: this.hostname,
					plansType: this.getQueryParam('plans_type'),
				},
				this.$absmartly,
			)
		},
	},

	methods: {
		storeFunnel(appAreaConfig) {
			this.$store.commit('location/setAppAreaConfig', appAreaConfig)
			this.$store.commit('location/setPaywallRoute', appAreaConfig.paywall.routeName)

			this.$analytic.setUserProperties({
				FunnelId: appAreaConfig.funnel.id,
				PaywallRoute: appAreaConfig.paywall.routeName,
			})

			Jet.setOnboarding({
				onboardingFunnelId: appAreaConfig.funnel.id,
				onboardingFunnelType: appAreaConfig.funnel.slug,
				onboardingUrlPath: this.$route.path,
			})

			Sentry.setTags({
				funnelId: appAreaConfig.funnel.id,
				funnelType: appAreaConfig.funnel.slug,
			})
		},

		fireSentryError(message, extras) {
			Sentry.withScope((scope) => {
				scope.setTags({
					funnelTypeError: true,
				})
				scope.setExtras(extras)
				scope.captureMessage(message)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.funnel-container {
	min-height: 100vh;
	min-height: 100dvh;
}
</style>
