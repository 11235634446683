import { addWeeks, differenceInDays, differenceInMonths } from 'date-fns'
import addMonths from 'date-fns/addMonths'

const BodyMassIndexCalculator = {
	calc: (weightInKilograms, heightInMeters) => {
		if (heightInMeters <= 0) {
			return null
		}

		return weightInKilograms / (heightInMeters * heightInMeters)
	},
}

export const WeightLossCalculator = {
	/**
	 * Predicts weight loss for different difficulties
	 * @param weight in kg
	 * @param weightGoal in kg
	 * @param height in cm
	 * @returns {{difficulty: string, numberOfWeeks: number, firstSpeedInKg, targetDate: Date}[]}
	 */
	predictWeightLoss: ({ weight, weightGoal, height }) => {
		const heightInMeters = height / 100
		const predictParams = [
			{
				difficulty: 'normal',
				defaultSpeed: 0.5,
				bmiToSpeed: [
					{ bmiRange: { lowerBound: 0, upperBound: 25 }, speed: 0.5 },
					{ bmiRange: { lowerBound: 25, upperBound: 30 }, speed: 0.8 },
					{ bmiRange: { lowerBound: 30, upperBound: 40 }, speed: 1 },
					{ bmiRange: { lowerBound: 40, upperBound: 50 }, speed: 1.3 },
					{ bmiRange: { lowerBound: 50, upperBound: Infinity }, speed: 1.8 },
				],
			},
			{
				difficulty: 'hard',
				defaultSpeed: 0.7,
				bmiToSpeed: [
					{ bmiRange: { lowerBound: 0, upperBound: 25 }, speed: 0.7 },
					{ bmiRange: { lowerBound: 25, upperBound: 30 }, speed: 1 },
					{ bmiRange: { lowerBound: 30, upperBound: 40 }, speed: 1.2 },
					{ bmiRange: { lowerBound: 40, upperBound: 50 }, speed: 1.5 },
					{ bmiRange: { lowerBound: 50, upperBound: Infinity }, speed: 2 },
				],
			},
			{
				difficulty: 'harder',
				defaultSpeed: 0.85,
				bmiToSpeed: [
					{ bmiRange: { lowerBound: 0, upperBound: 25 }, speed: 0.85 },
					{ bmiRange: { lowerBound: 25, upperBound: 30 }, speed: 1.15 },
					{ bmiRange: { lowerBound: 30, upperBound: 40 }, speed: 1.35 },
					{ bmiRange: { lowerBound: 40, upperBound: 50 }, speed: 1.65 },
					{ bmiRange: { lowerBound: 50, upperBound: Infinity }, speed: 2.15 },
				],
			},
			{
				difficulty: 'veryHard',
				defaultSpeed: 1,
				bmiToSpeed: [
					{ bmiRange: { lowerBound: 0, upperBound: 25 }, speed: 1 },
					{ bmiRange: { lowerBound: 25, upperBound: 30 }, speed: 1.3 },
					{ bmiRange: { lowerBound: 30, upperBound: 40 }, speed: 1.5 },
					{ bmiRange: { lowerBound: 40, upperBound: 50 }, speed: 1.8 },
					{ bmiRange: { lowerBound: 50, upperBound: Infinity }, speed: 2.3 },
				],
			},
		]

		return predictParams.map((param) =>
			WeightLossCalculator.predictWeightLossForSpeed({
				speed: param,
				weight,
				weightGoal,
				height: heightInMeters,
			}),
		)
	},
	predictWeightLossForSpeed: ({ speed, weight, weightGoal, height }) => {
		let currentWeight = weight
		let numberOfWeeks = 0
		let firstSpeed = null
		while (currentWeight > weightGoal) {
			const bmi = BodyMassIndexCalculator.calc(currentWeight, height) ?? 0
			const weekSpeed =
				speed.bmiToSpeed.find((item) => bmi >= item.bmiRange.lowerBound && bmi < item.bmiRange.upperBound)?.speed ??
				speed.defaultSpeed
			currentWeight -= weekSpeed
			numberOfWeeks += 1
			if (firstSpeed === null) {
				firstSpeed = weekSpeed
			}
		}
		const today = new Date()
		let targetDate = addWeeks(today, numberOfWeeks)
		// DIRTY HACK minimum 3 months of fasting instead of real number
		// consider removing
		if (Math.abs(differenceInMonths(today, targetDate)) < 3) {
			targetDate = addMonths(today, 3)
		}
		return {
			difficulty: speed.difficulty,
			numberOfWeeks: numberOfWeeks,
			firstSpeedInKg: firstSpeed ?? 0,
			targetDate,
		}
	},
}

/**
 * Calculates difference in kgs between initial and target weight on the event date
 * @param initialWeight {number} initial weight in kg
 * @param targetWeight {number} target weight in kg
 * @param eventDate {string | Date} event date in format which Date object could parse, for example 'YYYY-MM-DD' or Date object
 * @param targetDate {string | Date} target date in format which Date object could parse, for example 'YYYY-MM-DD' or Date object
 * @returns {number|null} how much weight user would lose in kgs
 */
export function calcEventWeightDifference({ initialWeight, targetWeight, eventDate, targetDate }) {
	if (!initialWeight || !targetWeight || !eventDate || !targetDate) {
		return null
	}
	const today = new Date()
	const ratio = Math.min(differenceInDays(new Date(eventDate), today) / differenceInDays(targetDate, today), 1)
	return (initialWeight - targetWeight) * ratio
}
