import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			funnel: {
				id: 'ob-female-one-predict-instead-of-two',
				locale: ['en'],
			},
		},
	},
] as ExperimentConfig[]
