import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-v2': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__activity_b'
					),
			},
		},
		'activity-female-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__yoga_b'
					),
			},
		},
		'activity-male-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__yoga_b'
					),
			},
		},
	},
	{
		'activity-female-v2': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__activity_c'
					),
			},
		},
		'activity-female-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__yoga_c'
					),
			},
		},
		'activity-male-yoga': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_plan_picker_update__yoga_c'
					),
			},
		},
	},
] as ExperimentConfig[]
