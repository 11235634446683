export default [
	{
		path: 'subscription/one-year-offer',
		name: 'account-subscription-one-year-offer',
		redirect: {
			name: 'account-subscription-one-quarter-offer-cancellation',
		},
	},

	{
		path: 'subscription/one-quarter-offer-cancellation',
		name: 'account-subscription-one-quarter-offer-cancellation',
		props: { scenarioId: 'one-quarter-offer-cancellation' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},

	{
		path: 'subscription/subscription-cancellation',
		name: 'subscription-cancellation',
		props: { scenarioId: 'subscription-cancellation' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},

	{
		path: 'subscription/subscription-pause-3-months',
		name: 'account-subscription-pause-3-months',
		props: { scenarioId: 'subscription-pause-3-months' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},
	{
		path: 'subscription/subscription-pause-1-month',
		name: 'account-subscription-pause-1-month',
		props: { scenarioId: 'subscription-pause-1-month' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},

	{
		path: 'subscription/one-dollar-forever',
		name: 'account-subscription-one-dollar-forever',
		props: { scenarioId: 'one-dollar-forever-cancellation' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},

	{
		path: 'subscription/success',
		name: 'subscription-success-screen',
		props: { scenarioId: 'success-screen' },
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/subscription/AccountSubscription'),
		meta: {
			hideHeader: true,
			requiresUser: true,
			requiresSubscription: true,
		},
	},
]
