import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_offering_trial_with_commitments__b'
					),
			},
		},
	},
	{
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_offering_trial_with_commitments__c'
					),
			},
		},
	},
] as ExperimentConfig[]
