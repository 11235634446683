import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-somatic-yoga': {
			funnel: {
				id: 'ob-female-somatic-yoga-comp-funnel',
				locale: ['en'],
			},
		},
	},
] as ExperimentConfig[]
