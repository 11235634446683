export default [
	{
		path: 'manage-subscription/cancel-reason',
		name: 'manage-subscription-cancel-reason',
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/CancelReason.vue'),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'manage-subscription/try-again-in-the-future',
		name: 'manage-subscription-try-again-in-the-future',
		component: () =>
			import(
				/* webpackChunkName: "manage-subscription" */
				'@/views/account/manage-subscription/TryAgainInTheFuture.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'manage-subscription/offer-creating',
		name: 'manage-subscription-offer-creating',
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/OfferCreating.vue'),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'manage-subscription/offer-creating-offer-forever',
		name: 'manage-subscription-offer-creating-offer-forever',
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/OfferCreating.vue'),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
		props: {
			offerForever: true,
		},
	},

	{
		path: 'manage-subscription/offer-forever',
		name: 'manage-subscription-offer-forever',
		component: () =>
			import(
				/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/cancel-offers/OneDollarForeverOffer.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'manage-subscription/offer',
		name: 'manage-subscription-offer',
		component: () =>
			import(/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/Offer.vue'),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'manage-subscription/monthly-offer',
		name: 'manage-subscription-monthly-offer',
		component: () =>
			import(
				/* webpackChunkName: "manage-subscription" */ '@/views/account/manage-subscription/cancel-offers/SpecialMonthlyOffer.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},

	{
		path: 'plan-management',
		name: 'plan-management',
		component: () =>
			import(/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/PlanManagement.vue'),
		meta: {
			requiresAuth: true,
			requiresUser: true,
		},
	},
	{
		path: 'plan-management/pause-subscription',
		name: 'pause-subscription',
		component: () =>
			import(/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/PauseSubscription.vue'),
		meta: {
			requiresAuth: true,
			requiresUser: true,
			hideHeader: true,
		},
	},
	{
		path: 'plan-management-commitment',
		name: 'plan-management-commitment',
		component: () =>
			import(
				/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/PlanManagementCommitment.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},
	{
		path: 'plan-management/cancellation-fee',
		name: 'plan-management-cancellation-fee',
		props: { scenarioId: 'cancellationFee' },
		component: () =>
			import(
				/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/cancellation/CancellationFee.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},
	{
		path: 'plan-management/switch-plan',
		name: 'plan-management-switch-plan',
		props: { scenarioId: 'switchPlan' },
		component: () =>
			import(
				/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/cancellation/CancellationFee.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},
	{
		path: 'plan-management/cancellation-fee-result',
		name: 'plan-management-cancellation-fee-result',
		component: () =>
			import(
				/* webpackChunkName: "plan-management" */ '@/views/account/manage-subscription/cancellation/CancellationFeeResult.vue'
			),
		meta: {
			hideHeader: true,
			requiresAuth: true,
			requiresUser: true,
		},
	},
]
