import { AppArea, ExperimentConfig } from '@/funnels/@types'

import web_autotest_female_funnel_check from './web_autotest_female_funnel_check'
import web_social_proof_first from './web_social_proof_first'
import web_female_yoga_exercises_examples from './web_female_yoga_exercises_examples'
import web_new_start_female_walking from './web_new_start_female_walking'
import web_fasting_welcome_like_activity from './web_fasting_welcome_like_activity'
import web_female_somatic_yoga_comp from './web_female_somatic_yoga_comp'
import web_back_to_fasting from './web_back_to_fasting'
import web_female_chair_yoga_comp from './web_female_chair_yoga_comp'
import web_wl_prediction_update from './web_wl_prediction_update'
import web_step_by_step_courses_add from './web_step_by_step_courses_add'
import web_show_daily_prices_on_commitment from './web_show_daily_prices_on_commitment'
import web_plan_picker_update from './web_plan_picker_update'
import web_offering_trial_with_commitments from './web_offering_trial_with_commitments'
import web_commitment_with_intro_prices from './web_commitment_with_intro_prices'
import web_emphasize_money_back_guarantee from './web_emphasize_money_back_guarantee'
import web_female_new_value_for_upgrades from './web_female_new_value_for_upgrades'
import web_pilates_new_value_for_upgrades from './web_pilates_new_value_for_upgrades'
import web_personalized_display_of_plans from './web_personalized_display_of_plans'
import web_predict_and_prices_separate from './web_predict_and_prices_separate'
import web_get_free_months from './web_get_free_months'
import web_get_free_months_female from './web_get_free_months_female'
import web_fallback_for_payment_failure from './web_fallback_for_payment_failure'
import web_inputs_optimization from './web_inputs_optimization'
import web_one_predict_instead_of_two from './web_one_predict_instead_of_two'
import web_paywall_yoga_female_comp from './web_paywall_yoga_female_comp'
import web_paywall_somatic_female_comp from './web_paywall_somatic_female_comp'
import web_male_new_value_for_upgrades from './web_male_new_value_for_upgrades'

const experimentsConfig: Partial<Record<AppArea, { [key: string]: ExperimentConfig[] }>> = {
	main: {
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
	},
	female: {
		web_autotest_female_funnel_check,
		web_social_proof_first,
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
		web_wl_prediction_update,
		web_show_daily_prices_on_commitment,
		web_commitment_with_intro_prices,
		web_female_new_value_for_upgrades,
		web_predict_and_prices_separate,
		web_fallback_for_payment_failure,
		web_one_predict_instead_of_two,
		web_offering_trial_with_commitments,
		web_get_free_months_female,
	},
	male: {
		web_fasting_welcome_like_activity,
		web_back_to_fasting,
		web_male_new_value_for_upgrades,
	},

	'main-activity': {},
	'activity-female-v2': {
		web_social_proof_first,
		web_wl_prediction_update,
		web_plan_picker_update,
		web_inputs_optimization,
	},
	'activity-male': {},

	'activity-female-pilates': {
		web_social_proof_first,
		web_wl_prediction_update,
		web_step_by_step_courses_add,
		web_show_daily_prices_on_commitment,
		web_emphasize_money_back_guarantee,
		web_pilates_new_value_for_upgrades,
		web_personalized_display_of_plans,
		web_get_free_months,
		web_inputs_optimization,
	},

	'main-yoga': {},
	'activity-female-yoga': {
		web_female_yoga_exercises_examples,
		web_female_chair_yoga_comp,
		web_wl_prediction_update,
		web_plan_picker_update,
		web_paywall_yoga_female_comp,
	},
	'activity-male-yoga': {
		web_plan_picker_update,
		web_emphasize_money_back_guarantee,
	},

	'activity-female-somatic-yoga': {
		web_female_somatic_yoga_comp,
		web_paywall_somatic_female_comp,
	},

	'main-walking': {},
	'activity-female-walking': {
		web_new_start_female_walking,
		web_wl_prediction_update,
		web_emphasize_money_back_guarantee,
		web_inputs_optimization,
	},
	'activity-male-walking': {},

	'activity-male-chair-workouts': {},
}

export default experimentsConfig
