import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-pilates': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_new_value_for_upgrades_comitment'
					),
			},
		},
	},
] as ExperimentConfig[]
