import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_predict_and_prices_separate'
					),
			},
		},
	},
] as ExperimentConfig[]
