import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_fallback_for_payment_failure'
					),
			},
		},
	},
] as ExperimentConfig[]
