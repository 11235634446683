export const PAYMENT_METHODS = {
	card: 1,
	paypal: 1,
	apple: 1,
}

export const FEATURES = {
	SIMPLE_PREMIUM: 'simple_premium',
	WORKBOOK_BUNDLE: 'simple_workbook_bundle',
}
