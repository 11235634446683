import type { ExperimentConfig } from '../@types'

export default [
	{
		female: {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_show_daily_prices_on_commitment__female'
					),
			},
		},
		'activity-female-pilates': {
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_show_daily_prices_on_commitment__pilates'
					),
			},
		},
	},
] as ExperimentConfig[]
