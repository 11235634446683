import { addDays, addMonths } from 'date-fns'

function getPeriodType(periodNumber, tags) {
	return periodNumber == 1 && ['trial', 'in_intro', 'in_commitment'].some((item) => tags.includes(item))
		? 'trial'
		: 'normal'
}

export function convertPaltaSubscription(subscription, userId) {
	const { pricePoint, periodNumber, tags, currentPeriodStartAt, currentPeriodEndAt, canceledAt } = subscription
	const { nextTotalPrice, currencyCode, ident } = pricePoint
	return {
		id: subscription.id,
		user_id: userId,
		price: parseFloat(nextTotalPrice),
		payment_method: null, // because palta api does not yet provide a payment_method
		currency: currencyCode.toLowerCase(),
		product_id: ident,
		period: null,
		period_type: getPeriodType(periodNumber, tags),
		created_at: currentPeriodStartAt,
		current_period_end_at: currentPeriodEndAt,
		cancelled: Boolean(canceledAt),
		is_new: false,
		provider: 'palta',
		is_fallback: true,
	}
}

/**
 * Function to create a fake Palta subscription object based on the pricePoint
 *
 * @typedef {Object} Service
 * @property {string} featureIdent - Identifier for the feature.
 * @property {string} featureType - Type of the feature.
 * @property {number} quantity - Quantity of the feature.
 *
 * @typedef {Object} PricePoint
 * @property {number} storeType - The type of the store.
 * @property {string} ident - The identifier of the price point.
 * @property {string} name - The name of the price point.
 * @property {number} priority - The priority of the price point.
 * @property {string} currencyCode - The code of the currency.
 * @property {Object} parameters - Additional parameters for the price point.
 * @property {string} type - The type of the price point.
 * @property {string} nextBasePrice - The base price for the next period.
 * @property {number} nextPeriodValue - The value of the next period.
 * @property {string} nextPeriodType - The type of the next period.
 * @property {string} introBasePrice - The base price for the intro period.
 * @property {number} introPeriodValue - The value of the intro period.
 * @property {string} introPeriodType - The type of the intro period.
 * @property {string} nextDiscountPrice - The discount price for the next period.
 * @property {string} nextDiscountPercentage - The discount percentage for the next period.
 * @property {string} nextTotalPrice - The total price for the next period.
 * @property {string} introDiscountPrice - The discount price for the intro period.
 * @property {string} introDiscountPercentage - The discount percentage for the intro period.
 * @property {string} introTotalPrice - The total price for the intro period.
 * @property {Service[]} services - Array of service objects.
 *
 * @param {PricePoint} pricePoint - The pricing model for the subscription.
 *
 * @returns {Object} - The fake Palta subscription object.
 */

export function makePaltaSubscriptionByPricePoint(pricePoint) {
	const addPeriodFn = pricePoint.introPeriodType === 'day' ? addDays : addMonths
	const tags = ['in_intro']
	if (pricePoint.parameters.isTrial === 'true') {
		tags.push('trial')
	}

	return {
		pricePoint: {
			ident: pricePoint.ident,
			nextTotalPrice: pricePoint.nextTotalPrice,
			introTotalPrice: pricePoint.introTotalPrice,
			currencyCode: pricePoint.currencyCode,
			services: [{ featureIdent: 'simple_premium', featureType: 'timebased', quantity: 1 }],
		},
		state: 'active',
		createdAt: new Date(),
		canceledAt: null,
		cancelType: null,
		cancelReason: null,
		currentPeriodStartAt: new Date(),
		currentPeriodEndAt: addPeriodFn(new Date(), pricePoint.introPeriodValue),
		periodNumber: 1,
		tags,
	}
}
