import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-v2': {
			funnel: {
				id: 'ob-female-activity-wl-prediction-update',
				locale: ['en'],
			},
		},
		female: {
			funnel: {
				id: 'ob-female-wl-prediction-update',
				locale: ['en'],
			},
		},
		'activity-female-walking': {
			funnel: {
				id: 'ob-female-walking-wl-prediction-update',
				locale: ['en'],
			},
		},
		'activity-female-yoga': {
			funnel: {
				id: 'ob-female-yoga-wl-prediction-update',
				locale: ['en'],
			},
		},
		'activity-female-pilates': {
			funnel: {
				id: 'ob-female-pilates-wl-prediction-update',
				locale: ['en'],
			},
		},
	},
] as ExperimentConfig[]
