import type { ExperimentConfig } from '../@types'

export default [
	{
		main: {
			funnel: {
				id: 'ob-main-landing-like-activity',
				locale: ['en'],
			},
		},
		male: {
			funnel: {
				id: 'ob-male-landing-like-activity',
				locale: ['en'],
			},
		},
		female: {
			funnel: {
				id: 'ob-female-landing-like-activity',
				locale: ['en'],
			},
		},
	},
] as ExperimentConfig[]
