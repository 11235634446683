import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-pilates': {
			funnel: {
				id: 'ob-female-pilates-step-by-step-courses',
				locale: ['en'],
			},
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_step_by_step_courses_add__b'
					),
			},
		},
	},
	{
		'activity-female-pilates': {
			funnel: {
				id: 'ob-female-pilates-step-by-step-courses',
				locale: ['en'],
			},
			paywall: {
				experiment: () =>
					import(
						/* webpackChunkName: "plan" */ '@/modules/Paywall/instances/experiments/web_step_by_step_courses_add__c'
					),
			},
		},
	},
] as ExperimentConfig[]
