import config from '@/config'
import axios from 'axios'
import { preferredPaymentMethodsMap } from '@/helpers/constants'

export default {
	namespaced: true,

	state: {
		purchasedProduct: null,
		upgrades: {
			original: null,
			discounted: null,
		},
		promocode: null,
		removeDiscountAfterCountdown: false,
		overridenTabsOrder: null,
		overridenDefaultPaymentTab: null,
		countdown: null,
		countdownIsVisible: false,
		countdownFinishedAt: null,
	},

	mutations: {
		setPurchasedProduct(state, product) {
			state.purchasedProduct = product
		},

		setUpgrades(state, upgrades) {
			state.upgrades.original = upgrades?.original
			state.upgrades.discounted = upgrades?.discounted
		},

		setPromocode(state, promocode) {
			state.promocode = promocode
		},

		setRemoveDiscountAfterCountdown(state, status) {
			state.removeDiscountAfterCountdown = status
		},

		setOverridenTabs(state, preferredPaymentMethod) {
			if (!preferredPaymentMethodsMap[preferredPaymentMethod]) {
				return
			}

			state.overridenTabsOrder = preferredPaymentMethodsMap[preferredPaymentMethod].tabsOrder
			state.overridenDefaultPaymentTab = preferredPaymentMethodsMap[preferredPaymentMethod].defaultPaymentTab
		},

		updateCountdown(state, value) {
			state.countdown = value
		},

		toggleCountdown(state, value) {
			state.countdownIsVisible = value
		},

		setCountdownFinishedAt(state, finishedAt) {
			state.countdownFinishedAt = finishedAt
		},
	},

	getters: {
		getPromocode(state) {
			return state.promocode
		},
	},

	actions: {
		async setPromocodeByName({ commit }, promocode) {
			try {
				const { data } = await axios.get(`${config('SimpleAPIUrl')}v1/api/marketing/promocode`, {
					params: {
						promocode,
					},
				})

				commit('setPromocode', data)
			} catch (e) {
				commit('setPromocode', null)
				throw e
			}
		},
	},
}
