import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import config from '../config'
import pluralizationRules from './plural.js'
import request from '@/helpers/request'
import Sentry from '@/modules/Sentry'
import { mergeDeep } from '@/helpers/utils'

Vue.use(VueI18n)

export const i18n = new VueI18n({
	locale: '', // set locale
	fallbackLocale: 'en',
	messages: {},
	pluralizationRules,
})

export async function loadLanguageAsync() {
	const queryParamLang = store.getters['location/getQueryParam']('lang')?.toLocaleLowerCase()
	const queryParamLpId = store.getters['location/getQueryParam']('lpid')

	const availableLanguages = config('SupportedLanguages')

	if (queryParamLang && availableLanguages.includes(queryParamLang)) {
		window.language = queryParamLang
	}

	const lang = window.language
	document.documentElement.setAttribute('lang', lang)

	if (i18n.locale === lang) {
		return
	}

	try {
		const localizationHost = `${config('ApiLocalization')}${lang}`
		const requests = [request(`${localizationHost}/web.json`)]

		if (queryParamLpId) {
			requests.push(request(`${localizationHost}/custom-landing.json`))
		}

		const responses = await Promise.allSettled(requests)

		const messages = responses
			.filter((result) => result.status === 'fulfilled')
			.map((result) => result.value)
			.reduce((acc, response) => Object.assign(acc, response), {})

		i18n.setLocaleMessage(lang, messages)
		i18n.locale = lang
		store.commit('i18n/setLanguage', lang)
		store.commit('status/setResourceReadiness', 'i18n')

		document.title = i18n.t('common.main_pagetitle')
	} catch (e) {
		if (e.name !== 'AxiosError') {
			Sentry.captureException(e)
		}
	}
}

export function mergeTranslationsWithFallback({ root = 'lpid', type, key = null, fallback = {} }) {
	const pathPrefix = `${root}.${type}.${key}`

	if (!key || !i18n.te(pathPrefix)) {
		return fallback
	}

	return mergeDeep(fallback, i18n.t(pathPrefix))
}
