/**
 * Checks if number is float or integer.
 * 1 - integer
 * 1.5 - float
 * @param number
 * @return {boolean}
 */
export function isInteger(number) {
	return number % 1 === 0
}

export function padZero(num) {
	return num < 10 ? `0${num}` : num
}

/**
 *
 * @param value
 * @param decimals
 * @returns {number}
 */
export function round(value = 0, decimals = 0) {
	return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

/**
 * Method for replacing variables in strings
 * For example: `template('Hello {foo}', { foo: 'world!' }) -> 'Hello world!'`
 * @param {string} string
 * @param {{[key]: string}} params
 */
export function replaceTemplateVariables(string = '', params) {
	return string.replace(/{(\w+)}/g, (match, key) => params[key] || match)
}

/**
Deeply merges two objects (target and source) by recursively merging
their properties. If a property is an array, it concatenates the arrays.
@function mergeDeep
@param {Object} target - The target object to be merged with the source object.
@param {Object} source - The source object with properties to be merged into the target object.
@returns {Object} - The merged object.
*/
export function mergeDeep(target, source) {
	const isObject = (obj) => obj && typeof obj === 'object'

	if (!isObject(target) || !isObject(source)) {
		console.error('Both target and source should be objects')
		return {}
	}

	Object.keys(source).forEach((key) => {
		const targetValue = target[key]
		const sourceValue = source[key]

		if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
			target[key] = [...sourceValue]
		} else if (isObject(targetValue) && isObject(sourceValue)) {
			target[key] = mergeDeep({ ...targetValue }, sourceValue)
		} else {
			target[key] = sourceValue
		}
	})

	return target
}

/**
 * Filters out invalid keys (null or undefined values) from the given object.
 *
 * @param {Object} obj - The input object to filter.
 * @returns {Object} filteredObj - The resulting object with invalid keys removed.
 */
export function filterInvalidKeys(obj) {
	const filteredObj = {}

	Object.keys(obj).forEach((key) => {
		if (obj[key] !== null && obj[key] !== undefined) {
			filteredObj[key] = obj[key]
		}
	})

	return filteredObj
}

export function debounce(fn, wait) {
	let timer
	return function (...args) {
		if (timer) {
			clearTimeout(timer)
		}
		const context = this
		timer = setTimeout(() => {
			fn.apply(context, args)
		}, wait)
	}
}

const loadedScripts = new Set()

// eslint-disable-next-line import/no-unused-modules
export function loadScript(scriptUrl) {
	return new Promise((resolve, reject) => {
		if (loadedScripts.has(scriptUrl)) {
			resolve()
			return
		}

		const scriptElement = document.createElement('script')
		scriptElement.src = scriptUrl

		scriptElement.onload = () => {
			loadedScripts.add(scriptUrl)
			resolve()
		}

		scriptElement.onerror = () => {
			reject(new Error(`Failed to load script: ${scriptUrl}`))
		}

		document.head.appendChild(scriptElement)
	})
}

/**
 * @param {Array<string>|Number} age Array with range (for example: ['30-39']) OR number
 * @returns {Number} Numerical representation of age decade
 */
export function getAgeDecadeNumber(age) {
	let ageInt = parseInt(age ?? 30)

	if (Array.isArray(age)) {
		ageInt = parseInt(age.toString().split('-')[0]) || 30
	}

	const quotient = Math.floor(ageInt / 10)

	if (quotient <= 2) {
		return 20
	} else if (quotient >= 7) {
		return 70
	}

	return quotient * 10
}

/**
 * @param {Array<string>|Number} age Array with range (for example: ['30-39']) OR number
 * @returns {String} Human readable name of age decade
 */
export function getAgeDecade(age) {
	const ageDecadeNumber = getAgeDecadeNumber(age)

	let text = ''

	switch (ageDecadeNumber) {
		case 20:
			text = 'twenties'
			break
		case 30:
			text = 'thirties'
			break
		case 40:
			text = 'forties'
			break
		case 50:
			text = 'fifties'
			break
		case 60:
			text = 'sixties'
			break
		case 70:
			text = 'seventies'
			break
	}

	return text
}

/**
 * Converts array of userFlags to object with boolean true for saving on backend. It's the required format for backend.
 * e.g. ['tag'] -> { tag: true }
 * @param flags
 * @returns {*}
 */
export function convertFlagsToObject(flags) {
	return flags.reduce((acc, flags) => {
		acc[flags] = true
		return acc
	}, {})
}
