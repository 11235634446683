import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			appAreaConfig: 'location/getAppAreaConfig',
			appTheme: 'getAppTheme',
		}),

		IS_DARK_THEME() {
			return ['dark', 'navy'].includes(this.appTheme)
		},

		AREA() {
			const { appArea } = this.appAreaConfig

			const BAU_MAIN = ['main', 'aff-main', 'wildcard-main'].includes(appArea)
			const BAU_FEMALE = ['female', 'aff-female', 'wildcard-female'].includes(appArea)
			const BAU_MALE = ['male', 'aff-male', 'wildcard-male'].includes(appArea)
			const BAU = [BAU_MAIN, BAU_FEMALE, BAU_MALE].some(Boolean)

			const ACTIVITY_MAIN = ['main-activity', 'aff-main-activity'].includes(appArea)
			const ACTIVITY_FEMALE = ['activity-female-v2', 'aff-activity-female'].includes(appArea)
			const ACTIVITY_MALE = ['activity-male', 'aff-activity-male'].includes(appArea)
			const ACTIVITY = [ACTIVITY_MAIN, ACTIVITY_FEMALE, ACTIVITY_MALE].some(Boolean)

			const YOGA_MAIN = ['main-yoga', 'aff-main-yoga'].includes(appArea)
			const YOGA_FEMALE = ['activity-female-yoga', 'aff-activity-female-yoga'].includes(appArea)
			const YOGA_MALE = ['activity-male-yoga', 'aff-activity-male-yoga'].includes(appArea)
			const YOGA = [YOGA_MAIN, YOGA_FEMALE, YOGA_MALE].some(Boolean)

			const WALKING_MAIN = ['main-walking', 'aff-main-walking'].includes(appArea)
			const WALKING_FEMALE = ['activity-female-walking', 'aff-activity-female-walking'].includes(appArea)
			const WALKING_MALE = ['activity-male-walking', 'aff-activity-male-walking'].includes(appArea)
			const WALKING = [WALKING_MAIN, WALKING_FEMALE, WALKING_MALE].some(Boolean)

			const PILATES_FEMALE = ['activity-female-pilates', 'aff-activity-female-pilates'].includes(appArea)
			const SOMATIC_FEMALE = ['activity-female-somatic-yoga', 'aff-activity-female-somatic-yoga'].includes(appArea)

			const CHAIR_WORKOUTS_MALE = ['activity-male-chair-workouts', 'aff-activity-male-chair-workouts'].includes(appArea)

			const MAIN_PILATES = ['main-pilates', 'aff-main-pilates'].includes(appArea)

			return {
				BAU,
				BAU_MAIN,
				BAU_FEMALE,
				BAU_MALE,

				ACTIVITY,
				ACTIVITY_MAIN,
				ACTIVITY_FEMALE,
				ACTIVITY_MALE,

				YOGA,
				YOGA_MAIN,
				YOGA_FEMALE,
				YOGA_MALE,

				WALKING,
				WALKING_MAIN,
				WALKING_FEMALE,
				WALKING_MALE,

				PILATES_FEMALE,
				SOMATIC_FEMALE,

				CHAIR_WORKOUTS_MALE,

				MAIN_PILATES,
			}
		},

		MAIN_FUNNEL_ROUTE_NAME() {
			return 'survey'
		},

		IS_MAIN_FUNNEL() {
			return this.AREA.BAU_MAIN
		},

		IS_MALE_FUNNEL() {
			return (
				this.AREA.BAU_MALE ||
				this.AREA.ACTIVITY_MALE ||
				this.AREA.YOGA_MALE ||
				this.AREA.WALKING_MALE ||
				this.AREA.CHAIR_WORKOUTS_MALE
			)
		},
		IS_FEMALE_FUNNEL() {
			return (
				this.AREA.BAU_FEMALE ||
				this.AREA.ACTIVITY_FEMALE ||
				this.AREA.PILATES_FEMALE ||
				this.AREA.YOGA_FEMALE ||
				this.AREA.SOMATIC_FEMALE ||
				this.AREA.WALKING_FEMALE
			)
		},
		IS_FEMALE_ACTIVITY_ONBOARDING_BUILDER_FUNNEL() {
			return (
				this.AREA.ACTIVITY_FEMALE ||
				this.AREA.PILATES_FEMALE ||
				this.AREA.YOGA_FEMALE ||
				this.AREA.SOMATIC_FEMALE ||
				this.AREA.WALKING_FEMALE
			)
		},
	},
}
