import { paltaApi } from '../api/index'
import { FEATURES } from '../helpers/constants'
import { makePaltaSubscriptionByPricePoint } from '../helpers/subscription'
import { paymentApiAxiosInstance } from '@/helpers/request'

export default {
	namespaced: true,

	state: {
		subscriptions: [],
	},

	getters: {
		getSubscriptionByFeature:
			(state) =>
			(feature = FEATURES.SIMPLE_PREMIUM) => {
				return state.subscriptions.find((subscription) => {
					return subscription.pricePoint.services.find(
						(service) => service.featureIdent == feature && subscription.cancelReason !== 'crossgrade',
					)
				})
			},
	},

	mutations: {
		store(state, subscriptions) {
			state.subscriptions = subscriptions
		},
	},

	actions: {
		async fetchSubscriptions(store, { user_id, ident }) {
			const { subscriptions = [], status } = await paltaApi.fetchSubscriptions(user_id)

			if (subscriptions.length) {
				store.commit('store', subscriptions)
			} else if (ident) {
				await store.dispatch('makeSubscriptionByIdent', { ident, user_id })
			}

			return {
				status,
				subscription: store.getters['getSubscriptionByFeature'](),
			}
		},

		purchase(store, params) {
			return paltaApi.purchase(params)
		},

		switchSubscription(store, params) {
			const { deferred = false, ...apiParams } = params
			return deferred ? paltaApi.migrateDeferred(apiParams) : paltaApi.upgrade(apiParams)
		},

		async makeSubscriptionByIdent(store, { ident, user_id }) {
			const pricePoints = await paltaApi.fetchPricePoints(user_id, {
				ident: [ident],
			})

			const pricePoint = pricePoints?.find((pricePoint) => pricePoint.ident === ident)

			const paltaSubscription = makePaltaSubscriptionByPricePoint(pricePoint)

			store.commit('store', [paltaSubscription])
		},

		cancelSubscription(store, params) {
			return paymentApiAxiosInstance.post('v1/api/subscription/paltabrain/cancel', params)
		},

		pauseSubscription({ commit, rootState }, params) {
			return paymentApiAxiosInstance.post('v1/api/subscription/paltabrain/pause', params).then(() => {
				commit(
					'setSubscription',
					{
						...rootState.subscription,
						paused_until_at: params.pause_until,
						is_pause_allowed: false,
						is_paused: true,
					},
					{ root: true },
				)
			})
		},

		resumeSubscription({ commit, rootState }, params) {
			return paymentApiAxiosInstance.post('v1/api/subscription/paltabrain/resume', params).then(() => {
				commit(
					'setSubscription',
					{
						...rootState.subscription,
						paused_until_at: null,
						is_pause_allowed: false,
						is_paused: false,
					},
					{ root: true },
				)
			})
		},

		chargeFee(store, params) {
			return paltaApi.chargeFee(params).then(() => {
				return store.commit(
					'setSubscription',
					{
						...store.rootState.subscription,
						cancelled: true,
						canceled_at: new Date().toISOString(),
						billing_periods_before_cancellation: 0,
					},
					{ root: true },
				)
			})
		},
	},
}
