<template>
	<div class="processing" :class="theme">
		<div v-if="labelText && !compact && labelPosition === 'top'" class="processing__label">
			<span class="processing__label-text">{{ labelText }}</span>
			<div v-if="!hideSpinner" class="spinner-border spinner-border-sm text-dark" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<div class="processing__bar" :class="{ inversed: inversedTextColor, compact }">
			<div class="processing__bar-inner" :style="{ width: value.progress + '%' }" />
			<div class="processing__bar-value" v-show="!compact">
				{{ progressBarText }}{{ $n(progressValueConverted, { style: 'percent' }) }}
			</div>
		</div>
		<div v-if="labelText && labelPosition === 'bottom'" class="processing__label processing__label-bottom">
			{{ labelText }}
		</div>
	</div>
</template>

<script>
import anime from 'animejs'

const PROCESSING_BAR_THEMES_LIST = [
	'primary',
	'primary-thin',
	'white',
	'primary-new',
	'light-primary',
	'white-glow',
	'white-contrasted',
	'white-fullscreen',
	'white-dark-theme',
	'male-primary-contrasted',
	'rounded-dark',
	'rounded-white',
	'rounded-light',
	'pilates',
	'primary-bottom',
	'green',
]
export default {
	name: 'ProcessingBar',

	props: {
		duration: {
			type: Number,
			required: true,
		},

		label: {
			type: String,
			default: '',
		},

		labelPosition: {
			type: String,
			default: 'top',
		},

		progressBarText: {
			type: String,
			default: '',
		},

		theme: {
			type: String,
			default: 'primary',
			validator(val) {
				return PROCESSING_BAR_THEMES_LIST.includes(val)
			},
		},

		switchOn: {
			type: Number,
			default: null,
		},

		switchLabel: {
			type: String,
			default: '',
		},

		switchInverseTextColor: {
			type: Boolean,
		},

		compact: {
			type: Boolean,
			default: false,
		},

		keyframes: {
			type: [Array, null],
			default: null,
		},

		switchDelay: {
			type: Number,
			default: 800,
		},

		hideSpinner: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			animation: null,
			labelText: this.label,
			value: {
				progress: 0,
			},

			switched: false,
			inversedTextColor: false,
		}
	},

	computed: {
		progressValueConverted() {
			return this.value.progress * 0.01
		},

		steps() {
			let array = []
			array.push(anime.random(10, 30))
			array.push(anime.random(array[0], 50))
			array.push(anime.random(array[1], 60))
			array.push(anime.random(array[2], 70))
			array.push(anime.random(array[3], 80))
			array.push(anime.random(array[4], 90))
			array.push(100)
			return array
		},

		easing() {
			let values = ['.37,.84,.59,.09', '.4,.75,.62,.21', '.44,.66,.66,.38']
			return `cubicBezier(${values[anime.random(0, 2)]})`
		},
	},

	methods: {
		rounding(val) {
			return parseInt(val)
		},
	},

	created() {
		const params = this.keyframes
			? {
					keyframes: this.keyframes,
			  }
			: {
					progress: this.steps,
					duration: this.duration,
			  }
		this.animation = anime({
			...params,
			targets: this.value,
			easing: this.easing,
			begin: (e) => {
				this.$emit('start', e)
			},
			complete: () => {
				this.$emit('onCompleted')
			},
			change: (e) => {
				this.$emit('onProgress', e.animations[0].currentValue)
			},
			update: (anim) => {
				if (!this.switchOn) {
					return
				}

				if (anim.progress > this.switchOn && !this.switched) {
					if (this.switchLabel) {
						this.labelText = this.switchLabel
					}

					if (this.switchInverseTextColor) {
						this.inversedTextColor = true
					}

					this.animation.pause()
					setTimeout(() => {
						this.animation.play()
						this.switched = true
					}, this.switchDelay)
				}
			},
		})
	},
}
</script>

<style lang="scss" scoped>
.processing {
	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 1rem;
		max-width: 475px;
		margin-left: auto;
		margin-right: auto;

		.spinner-border {
			margin-left: 0.8rem;
		}

		&-bottom {
			margin-top: var(--size-14);
		}
	}

	&__bar {
		height: 2.2rem;
		background-color: var(--processing-bar-bg-color);
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		border-radius: 6px;
		margin-top: 0.6rem;
		position: relative;
		overflow: hidden;
		transition: color 0.15s;
		color: var(--processing-bar-text-color);

		&-inner {
			width: 0;
			height: 100%;
		}

		&-value {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-weight: bold;
		}

		&.inversed {
			color: var(--processing-bar-text-color-inverted);
		}

		&.compact {
			height: 0.8rem;
			border-radius: 24px;
		}
	}
}

.processing.primary,
.processing.primary-new {
	.processing__label {
		.spinner-border {
			border: 0.15em solid #cecfd3;
			border-right-color: transparent;
		}
	}

	.processing__bar {
		&-inner {
			background-color: var(--processing-bar-bg-color-active);
		}
	}
}

.processing.primary-new {
	.processing__label {
		font-size: 1.1rem;
		font-weight: bold;
		color: #6a6d82;
	}
}

.processing.white {
	.processing__label {
		font-weight: bold;
		color: #fff;

		.spinner-border {
			border: 0.2em solid #fff;
			border-right-color: transparent;
		}
	}

	.processing__bar {
		&-inner {
			background-color: var(--processing-bar-bg-color-active);
		}
	}
}

.white-fullscreen {
	.processing__bar {
		max-width: 100%;
		height: 40px;
		border-radius: 0;
		background-color: #252736;

		&-inner {
			background-color: #fff;
		}
	}
}

.processing.white-contrasted {
	.processing__label {
		font-weight: bold;
		color: #fff;

		.spinner-border {
			border: 0.2em solid #fff;
			border-right-color: transparent;
		}
	}

	.processing__bar {
		&-inner {
			background-color: var(--processing-bar-bg-color-active);
		}
	}
}

.processing.light-primary {
	.processing__label {
		font-weight: bold;
		color: #fff;

		.spinner-border {
			border: 0.2em solid #fff;
			border-right-color: transparent;
		}
	}
	.processing__bar {
		&-inner {
			background-color: rgba(88, 65, 135, 0.8);
		}
	}
}

.processing.white-glow {
	.spinner-border {
		border: 0.15em solid #ffffff;
		border-right-color: transparent;
	}
	.processing__bar-inner {
		background-color: #7d8bf7;
	}
	.processing__bar {
		background-color: #ebe9ff;
		border: 1px solid #ebe9ff;
		border-radius: 10px;
		box-shadow: 0 4px 20px rgba(135, 125, 234, 0.3);
		margin-left: 0;
		margin-right: 0;
		max-width: none;
	}
}

.processing.white-dark-theme {
	.processing__label {
		font-weight: bold;
		color: #fff;

		.spinner-border {
			border: 0.2em solid #fff;
			border-right-color: transparent;
		}
	}
	.processing__bar {
		color: #fff;
		background-color: rgba(255, 255, 255, 0.4);
		&-inner {
			background-color: var(--Fill-White);
		}
	}
}

.processing.male-primary-contrasted {
	.processing__label {
		font-weight: bold;
		color: #fff;

		.spinner-border {
			border: 0.2em solid #fff;
			border-right-color: transparent;
		}
	}
	.processing__bar {
		height: 0.6rem;
		color: #fff;
		background-color: rgba(152, 164, 255, 0.17);
		&-inner {
			background-color: var(--primary-color);
		}
	}
}

.processing.rounded-dark {
	.processing__label {
		color: #fff;

		.spinner-border {
			border: 0.2em solid rgba(255, 255, 255, 0.6);
			border-right-color: transparent;
		}
	}
	.processing__bar {
		height: 2.5rem;
		padding: 4px;
		background-color: rgba(50, 54, 83, 0.2);
		border-radius: 1.5rem;
		max-width: 500px;
		color: #fff;

		&-inner {
			background-color: var(--primary-color);
			border-radius: 1.5rem;
		}
	}
}

.rounded-white {
	.processing__label {
		color: #fff;

		.spinner-border {
			border: 0.2em solid rgba(255, 255, 255, 0.6);
			border-right-color: transparent;
		}
	}
	.processing__bar {
		height: 2.5rem;
		padding: 4px;
		background-color: #ffffff14;
		border-radius: 1.5rem;
		max-width: 500px;
		color: var(--primary-color);

		&-inner {
			background-color: #fff;
			border-radius: 1.5rem;
		}
	}
}

.processing.rounded-light {
	.processing__label {
		color: var(--text-color-secondary);

		.spinner-border {
			border: 0.2em solid rgba(50, 54, 83, 0.2);
			border-right-color: transparent;
		}
	}
	.processing__bar {
		height: 2.5rem;
		padding: 4px;
		background-color: rgba(50, 54, 83, 0.08);
		border-radius: 1.5rem;
		max-width: 500px;
		color: var(--processing-bar-text-color);

		&-inner {
			background-color: var(--primary-color);
			border-radius: 1.5rem;
		}

		&.inversed {
			color: var(--processing-bar-text-color-inverted);
		}
	}
}

.processing.pilates {
	.processing__label {
		color: #323653;
		font-style: italic;
		font-weight: 500;
		.spinner-border {
			border: 0.2em solid rgba(50, 54, 83, 0.08);
			border-right-color: transparent;
		}
	}
	.processing__bar {
		height: 2.9rem;
		padding: 0.3rem;
		background-color: rgba(255, 255, 255, 0.4);
		border-radius: 1.5rem;
		color: #fff;

		@media (min-width: 560px) {
			max-width: 100%;
		}

		&-inner {
			background: linear-gradient(90deg, #7d8bf7 0%, #8952ff 100%);
			border-radius: 1.5rem;
		}
	}
}

.processing.primary-bottom {
	.processing__label {
		color: rgba(50, 54, 83, 0.6);
		font-size: 0.75rem;
	}

	.processing__bar {
		height: 2.5rem;
		background-color: rgba(50, 54, 83, 0.2);
		max-width: 100%;
		margin-top: 1.2rem;
		position: relative;
		overflow: hidden;
		border-radius: 0;
		transition: color 0.15s;
		color: #fff;

		&-inner {
			background: var(--primary-color);
			height: 100%;
			border-radius: 0;
		}

		&-value {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-weight: bold;
		}
	}
}

.processing.green {
	.processing__bar {
		height: 2.5rem;
		max-width: 100%;
		padding: 0;
		margin: 0;
		border-radius: 0;
		background-color: #32365314;
		color: #fff;

		&-inner {
			background-color: #38af76;
		}
	}
}

.processing.primary-thin {
	.processing__bar {
		background: var(--Fill-Accent-16);
		height: var(--size-8);

		&-value {
			display: none;
		}

		&-inner {
			background: var(--Fill-Accent-100);
		}
	}
}
</style>
