import type { ExperimentConfig } from '../@types'

export default [
	{
		'activity-female-yoga': {
			funnel: {
				id: 'ob-female-yoga-exercises',
				locale: ['en'],
			},
		},
	},
] as ExperimentConfig[]
