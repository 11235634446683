<template>
	<div class="header">
		<div class="container-fluid">
			<div class="header-row" :class="{ 'header-row_centered': centered }">
				<div class="logo" :class="`logo_size_${logoSize}`">
					<img :src="logoSrc" alt="Simple App" />
				</div>
				<div class="right-col">
					<slot name="rightCol"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseHeader',

	props: {
		theme: {
			type: String,
			default: 'primary',
			validator: (value) => ['primary', 'white', 'gray'].includes(value),
		},

		logoSize: {
			type: String,
			default: 'm',
			validator: (value) => ['s', 'm'].includes(value),
		},

		centered: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		logoSrc() {
			if (this.theme === 'white') {
				return require('@/assets/logos/logo-white-inline.svg')
			} else if (this.theme === 'gray') {
				return require('@/assets/logos/logo-gray-inline.svg')
			}

			return require('@/assets/logos/logo-primary-inline.svg')
		},
	},
}
</script>

<style scoped lang="scss">
.header {
	width: 100%;

	&-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		&.header-row_centered {
			justify-content: center;
		}
	}

	.right-col {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.logo {
		&_size {
			&_s {
				img {
					height: var(--size-22);
				}
			}
			&_m {
				img {
					height: var(--size-26);
				}
			}
		}
	}
}
</style>
