import { AppAreaConfig, DomainConfig, OverridesObject } from '@/funnels/@types'
import merge from 'lodash.merge'

const female: AppAreaConfig = {
	appArea: 'female',
	landing: {
		route: {
			path: '/f',
			name: 'female-funnel',
		},
	},
	funnel: {
		id: 'ob-female-funnel',
		route: {
			path: '/f/ob/:page*',
			name: 'female-survey',
		},
		slug: 'female',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-female-commitment',
	},
	upsell: {},
	overrides: ({ plansType }): OverridesObject => {
		if (plansType === 'short') {
			return {
				paywall: {
					routeName: 'plan-female',
				},
			}
		}

		return {
			paywall: {
				routeName: 'plan-female-commitment',
			},
		}
	},
}

const male: AppAreaConfig = {
	appArea: 'male',
	landing: {
		route: {
			path: '/m',
			name: 'male-funnel',
		},
	},
	funnel: {
		id: 'ob-male-funnel',
		route: {
			path: '/m/ob/:page*',
			name: 'male-survey',
		},
		slug: 'male',
		theme: 'navy',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-male',
	},
	upsell: {},
}

const main: AppAreaConfig = {
	appArea: 'main',
	landing: {
		route: null, // this route defined in router/index.js
	},
	funnel: {
		id: 'ob-universal-funnel',
		route: {
			path: '/main/ob/:page*',
			name: 'survey',
		},
		slug: 'main',
		theme: 'light',
		locale: ['en', 'de-DE', 'fr-FR', 'tr', 'sv', 'pl', 'pt-BR', 'it-IT', 'es-ES', 'es-419', 'da', 'nb', 'nl'],
		redirectTo: {
			sex: {
				male: male,
				female: female,
			},
		},
	},
	prePaywall: {},
	paywall: {
		routeName: 'plan-main',
	},
	upsell: {},
}

// Необходимо добавить 'aff-main' | 'aff-female' | 'aff-male' в тип AppArea
// в файле src/funnels/@types/index.ts

export const bauAff: DomainConfig = {
	'/': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, main, { appArea: 'aff-main' }),
	'/f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, female, {
		appArea: 'aff-female',
		paywall: { routeName: 'plan-female' },
	}),
	'/m': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, male, { appArea: 'aff-male' }),
}

export const bauWildcard: DomainConfig = {
	'/': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, main, { appArea: 'wildcard-main' }),
	'/f': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, female, { appArea: 'wildcard-female' }),
	'/m': merge<{}, AppAreaConfig, Partial<AppAreaConfig>>({}, male, { appArea: 'wildcard-male' }),
}

export default {
	'/': main,
	'/f': female,
	'/m': male,
} as DomainConfig
